import React from 'react';
import { FaInstagram, FaFacebook, FaLinkedin, FaMapMarkerAlt } from "react-icons/fa"; // Updated import
import blogpost1 from '../components/Image/blog/1.jpg';
import blogpost2 from '../components/Image/blog/2.jpg';
import FadeUpComponent from './Animation/FadeUpComponent';

const Footer = () => {
  return (
    <>
      <section className='w-full bg-[#0f4769]'>
        <div className="max-w-[1300px] m-auto py-12 px-8 lg:grid-cols-1 gap-4 text-white">
          <div className="lg:flex lg:flex-wrap lg:justify-between">
            {/* About Us Section */}
            <div className="lg:w-1/2 xl:w-[35%] mb-4 lg:mb-0">
              <h6 className="uppercase mb-3 text-xl font-bold">
                <FadeUpComponent>ABOUT US</FadeUpComponent>
              </h6>
              <p className="text-[16px] mb-4">
                Resolute is a forward-thinking investment firm dedicated to strategic diversification
                and sustainable growth across dynamic sectors. We empower ventures that redefine industry
                standards and drive lasting financial impact.
              </p>
              <p className="text-sm">
                Any Queries: <span>+91 6353106274</span>
              </p>
            </div>

            {/* Portfolio Section */}
            <div className="lg:w-1/2 xl:w-[20%] mb-4 lg:mb-0">
              <h6 className="uppercase mb-3 text-xl font-bold">
                <FadeUpComponent>Portfolio</FadeUpComponent>
              </h6>
              <ul className="list-none text-[16px] mb-4">
                <li><a href='https://www.sanghicement.com/' target="_blank" rel='noopener noreferrer'>Sanghi Cement</a></li>
                <li><a href='https://www.sharesquare.properties/' target="_blank" rel='noopener noreferrer'>ShareSquare</a></li> 
                <li><a href='https://www.hairdramacompany.com/' target="_blank" rel='noopener noreferrer'>Hair Drama Co.</a></li>
                <li><a href='https://delhitoofans.com/' target="_blank" rel='noopener noreferrer'>Delhi Toofans</a></li>
                <li><a href='https://hyderabadtoofans.net/' target="_blank" rel='noopener noreferrer'>Hyderabad Toofans</a></li>
              </ul>
            </div>

            {/* Recent Post Section */}
            <div className="lg:w-1/2 xl:w-[35%] mb-4 lg:mb-0">
              <h6 className="uppercase mb-3 text-xl font-bold">
                <FadeUpComponent>Recent post</FadeUpComponent>
              </h6>
              <div className="flex flex-wrap mb-4">
                <div className="w-1/4 xl:w-1/6 mb-4 xl:mb-0 pr-2">
                  <img src={blogpost1} alt="Recent Post 1" className="w-full h-full object-cover rounded " />
                </div>
                <div className="w-3/4 xl:w-5/6 mb-4 xl:mb-0 flex flex-col">
                  <a href="https://www.thehindu.com/sport/other-sports/pvl-libero-anand-a-game-changer-with-exceptional-digs/article67988777.ece" target="_blank" rel='noopener noreferrer' className="text-sm mr-1 font-semibold">
                    Libero Anand — a game-changer with exceptional digs
                  </a>
                  <span className="text-sm">Mar 24, 2024</span>
                </div>
              </div>
              <div className="flex flex-wrap mb-4">
                <div className="w-1/4 xl:w-1/6 mb-4 xl:mb-0 pr-2 object-cover">
                  <img src={blogpost2} alt="Recent Post 2" className="w-full h-full rounded" />
                </div>
                <div className="w-3/4 xl:w-5/6 mb-4 xl:mb-0 flex flex-col">
                  <a href="https://sportstar.thehindu.com/volleyball/pvl-2024-delhi-toofans-pro-volleyball-league-calicut-heroes-final-maiden-season/article67971123.ece" target="_blank" rel='noopener noreferrer' className="text-sm mr-1 font-semibold">
                    Fresh-faced Delhi Toofans trumps odds to enter title clash
                  </a>
                  <span className="text-sm">Mar 20, 2024</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Bottom Section */}
      <section className='bg-[#0A235C] w-full'>
        <div className='max-w-[1300px] m-auto py-2 lg:grid-cols-1'>
          <div className="lg:flex lg:justify-between lg:items-center m-4 text-white">
            <p className="text-sm font-bold">2024 ResoluteCorp, All rights reserved</p>
            <div className="flex lg:justify-end gap-2">
              {/* Social Media Links */}
              <a href="https://www.facebook.com/people/Resolute-Corp/61559078189329/" target="_blank" rel="noreferrer">
                <FaFacebook size={20} />
              </a>
              <a href="https://www.linkedin.com/company/resolutecorp/" target="_blank" rel="noreferrer">
                <FaLinkedin size={20} />
              </a>
              <a href="https://www.instagram.com/resolute.corp/" target="_blank" rel="noreferrer">
                <FaInstagram size={20} />
              </a>
              {/* Location Link */}
              <a
                href="https://www.google.com/maps/place/Resolute+Corp/@22.9929562,72.496642,771m/data=!3m2!1e3!4b1!4m6!3m5!1s0xa670e4a7610b008f:0xb4d0c70b8f1752f8!8m2!3d22.9929562!4d72.4992223!16s%2Fg%2F11wx9xs5w5?entry=ttu&g_ep=EgoyMDI1MDEwNy4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                title="ResoluteCorp"
              >
                <FaMapMarkerAlt size={20} />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
